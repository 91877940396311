import {CreateMenu, Menu} from "@casavina/feature/models/menu";
import {createActionGroup, emptyProps, props} from "@ngrx/store";

export const MenuPageActions = createActionGroup({
	source: "Core - Menu Page",
	events: {
		"Load Menu": emptyProps(),
		"Create Menu": props<{ menu: CreateMenu }>(),
		"Update Menu": props<{ menu: CreateMenu; id: number }>(),
		"Delete Menu": props<{ id: number }>(),
		"Local Update Menu": props<{ menu: Menu }>()
	}
});

export const MenuApiActions = createActionGroup({
	source: "Core - Menu API",
	events: {
		"Load Menu Success": props<{ menu: Menu[] }>(),
		"Load Menu Failure": props<{ error: string }>(),
		"Create Menu Success": props<{ menu: Menu }>(),
		"Create Menu Failure": props<{ error: string }>(),
		"Update Menu Success": props<{ menu: Menu }>(),
		"Update Menu Failure": props<{ error: string }>(),
		"Delete Menu Success": props<{ id: number }>(),
		"Delete Menu Failure": props<{ error: string }>()
	}
});
