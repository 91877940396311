import {Ingredient} from "@casavina/feature/models/ingredient";
import {createEntityAdapter, EntityState} from "@ngrx/entity";
import {createFeature, createReducer, createSelector, on} from "@ngrx/store";
import {IngredientApiActions, IngredientPageActions} from "./ingredient.actions";

interface IngredientState extends EntityState<Ingredient> {
	error: string;
	status: "pending" | "loading" | "success" | "error";
}

const ingredientAdapter = createEntityAdapter<Ingredient>();

const initialState: IngredientState = ingredientAdapter.getInitialState({
	error: "",
	status: "pending"
});

export const ingredientFeature = createFeature({
	name: "ingredient",
	reducer: createReducer(
		initialState,
		on(IngredientPageActions.loadIngredient, (state) => ({
			...state,
			status: "loading"
		})),
		on(IngredientApiActions.loadIngredientSuccess, (state, {ingredient}) =>
			ingredientAdapter.setAll(ingredient, {
				...state,
				error: "",
				status: "success"
			})
		),
		on(IngredientApiActions.loadIngredientFailure, (state, {error}) => ({
			...state,
			error: error,
			status: "error"
		})),
		on(IngredientPageActions.createIngredient, (state) => ({
			...state,
			status: "loading"
		})),
		on(IngredientApiActions.createIngredientSuccess, (state, {ingredient}) =>
			ingredientAdapter.setOne(ingredient, {
				...state,
				error: "",
				status: "success"
			})
		),
		on(IngredientApiActions.createIngredientFailure, (state, {error}) => ({
			...state,
			error: error,
			status: "error"
		})),
		on(IngredientPageActions.updateIngredient, (state) => ({
			...state,
			status: "loading"
		})),
		on(IngredientApiActions.updateIngredientSuccess, (state, {ingredient}) =>
			ingredientAdapter.upsertOne(ingredient, {
				...state,
				error: "",
				status: "success"
			})
		),
		on(IngredientApiActions.updateIngredientFailure, (state, {error}) => ({
			...state,
			error: error,
			status: "error"
		})),
		on(IngredientPageActions.deleteIngredient, (state) => ({
			...state,
			status: "loading"
		})),
		on(IngredientApiActions.deleteIngredientSuccess, (state, {id}) =>
			ingredientAdapter.removeOne(id, {
				...state,
				error: "",
				status: "success"
			})
		),
		on(IngredientApiActions.deleteIngredientFailure, (state, {error}) => ({
			...state,
			error: error,
			status: "error"
		}))
	),
	extraSelectors: ({selectEntities, selectIds}) => ({
		selectAll: createSelector(
			selectEntities,
			selectIds,
			(ingredientEntities, ingredientIds) =>
				ingredientIds
					.map((id) => ingredientEntities[id])
					.filter(
						(ingredient): ingredient is Ingredient => ingredient !== undefined
					)
		),
		selectIngredientById: (id: number) =>
			createSelector(
				selectEntities,
				(ingredientEntities) => ingredientEntities[id]
			)
	})
});
