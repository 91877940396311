import {inject} from "@angular/core";
import {CanActivateFn, Router} from "@angular/router";
import {FacadeService} from "@casavina/utils/facade-store/facade.service";
import {map} from "rxjs";

export const nonAuthGuard: CanActivateFn = (route, state) => {
	const facadeService = inject(FacadeService);
	const router = inject(Router);
	return facadeService
		.isLogin()
		.pipe(map((isLogin) => (isLogin ? router.parseUrl("/") : true)));
};
