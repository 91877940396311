<div class = "navbar-items" >
	<ng-container *ngFor = "let item of navbarItems" >
		<ng-container *ngTemplateOutlet = "
        item.items && item.items.length > 0 ? nestedNavbarItem : navbarItem;
        context: { item: item }
      " ></ng-container >
	</ng-container >
</div >

<ng-template #navbarItem let-item = "item" >
	<a *ngIf = "item.visible !== undefined ? (item.visible | async) : true"
	   [routerLink] = "item.routerLink"
	   mat-button >
		<div [routerLinkActiveOptions] = "{ exact: true }" class = "navbar-items-details" routerLinkActive = "active" >
			{{ item.label }}
		</div >
	</a >
</ng-template >

<ng-template #nestedNavbarItem let-item = "item" >
	<a #menuTrigger = "matMenuTrigger"
	   *ngIf = "item.visible !== undefined ? (item.visible | async) : true"
	   [matMenuTriggerFor] = "menu"
	   [routerLink] = "item.routerLink"
	   mat-button >
		<div [routerLinkActiveOptions] = "{ exact: true }" class = "navbar-items-details" routerLinkActive = "active" >
			{{ item.label }}
			<mat-icon fontSet = "material-symbols-outlined" >
				{{ menuTrigger.menuOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
			</mat-icon >
			<mat-menu #menu = "matMenu" >
				<ng-container *ngFor = "let childItem of item.items" >
					<button *ngIf = "
              childItem.visible !== undefined
                ? (childItem.visible | async)
                : true
            " [routerLink] = "childItem.routerLink" mat-menu-item >
						{{ childItem.label }}
					</button >
				</ng-container >
			</mat-menu >
		</div >
	</a >
</ng-template >
