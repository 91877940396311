import {AsyncPipe, NgForOf, NgIf, NgTemplateOutlet} from "@angular/common";
import {ChangeDetectionStrategy, Component, Input} from "@angular/core";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {NavbarItem} from "../../models/navbar-item";

@Component({
	selector: "casavina-navbar-items",
	standalone: true,
	imports: [
		NgForOf,
		MatMenuModule,
		MatButtonModule,
		MatIconModule,
		NgTemplateOutlet,
		NgIf,
		RouterLink,
		RouterLinkActive,
		AsyncPipe
	],
	templateUrl: "./navbar-items.component.html",
	styleUrls: ["./navbar-items.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarItemsComponent {
	@Input({required: true}) public navbarItems: NavbarItem[] = [];
}
