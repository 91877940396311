import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {CreateReservation, Reservation} from "@casavina/feature/models/reservation";
import {environment} from "@casavina/utils/environments/environment";
import {Observable} from "rxjs";

@Injectable({
	providedIn: "root"
})
export class ReservationService {
	private readonly apiUrl: string = environment.apiUrl;
	private httpOptions = {
		headers: new HttpHeaders({
			"Content-Type": "application/json"
		}),
		withCredentials: true
	};

	constructor(private http: HttpClient) {
	}

	public createReservation(
		reservation: CreateReservation
	): Observable<{ id: string }> {
		return this.http.post<{ id: string }>(
			`${this.apiUrl}/v1/reservations`,
			reservation,
			this.httpOptions
		);
	}

	public getReservationsInRange(
		start?: string,
		end?: string
	): Observable<Reservation[]> {
		let params = new HttpParams();
		if (start) {
			params = params.set("start", start);
		}
		if (end) {
			params = params.set("end", end);
		}
		return this.http.get<Reservation[]>(`${this.apiUrl}/v1/reservations`, {
			params
		});
	}

	public updateReservation(reservation: Reservation): Observable<void> {
		return this.http.put<void>(
			`${this.apiUrl}/v1/reservations`,
			reservation,
			this.httpOptions
		);
	}
}
