import {Reservation} from "@casavina/feature/models/reservation";
import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {createFeature, createReducer, createSelector, on} from "@ngrx/store";
import {ReservationApiActions, ReservationPageActions} from "./reservation.actions";

interface ReservationState extends EntityState<Reservation> {
	error: string;
	status: "pending" | "loading" | "success" | "error";
}

const reservationAdapter: EntityAdapter<Reservation> =
	createEntityAdapter<Reservation>();

const initialState: ReservationState = reservationAdapter.getInitialState({
	error: "",
	status: "pending"
});

export const reservationFeature = createFeature({
	name: "reservation",
	reducer: createReducer(
		initialState,
		on(ReservationPageActions.loadReservation, (state) => ({
			...state,
			status: "loading"
		})),
		on(
			ReservationApiActions.loadReservationSuccess,
			(state, {reservations}) =>
				reservationAdapter.setAll(reservations, {
					...state,
					error: "",
					status: "success"
				})
		),
		on(ReservationApiActions.loadReservationFailure, (state, {error}) => ({
			...state,
			error: error,
			status: "error"
		})),
		on(ReservationPageActions.createReservation, (state) => ({
			...state,
			error: "",
			status: "loading"
		})),
		on(ReservationApiActions.createReservationSuccess, (state) => ({
			...state,
			error: "",
			status: "success"
		})),
		on(ReservationApiActions.createReservationFailure, (state, {error}) => ({
			...state,
			error: error,
			status: "error"
		})),
		on(ReservationPageActions.toggleReservationStatus, (state) => ({
			...state,
			error: "",
			status: "loading"
		})),
		on(
			ReservationApiActions.toggleReservationStatusSuccess,
			(state, {reservation}) =>
				reservationAdapter.upsertOne(reservation, {
					...state,
					error: "",
					status: "success"
				})
		),
		on(
			ReservationApiActions.toggleReservationStatusFailure,
			(state, {error}) => ({
				...state,
				error: error,
				status: "error"
			})
		)
	),
	extraSelectors: ({selectEntities, selectIds}) => ({
		selectAll: createSelector(
			selectEntities,
			selectIds,
			(reservationEntities, reservationIds) =>
				reservationIds
					.map((id) => reservationEntities[id])
					.filter(
						(reservation): reservation is Reservation =>
							reservation !== undefined
					)
		)
	})
});
