import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {environment} from "@casavina/utils/environments/environment";
import {Observable} from "rxjs";

@Injectable({
	providedIn: "root"
})
export class AuthService {
	private readonly apiUrl: string = environment.apiUrl;
	private httpOptions = {
		headers: new HttpHeaders({
			"Content-Type": "application/json"
		}),
		withCredentials: true
	};

	constructor(private http: HttpClient) {
	}

	public login(username: string, password: string): Observable<void> {
		return this.http.post<void>(
			`${this.apiUrl}/v1/auth/login`,
			{
				username,
				password
			},
			this.httpOptions
		);
	}

	public logout(): Observable<void> {
		return this.http.post<void>(
			`${this.apiUrl}/v1/auth/logout`,
			{},
			this.httpOptions
		);
	}
}
