import {Directive} from "@angular/core";

@Directive({
	selector: "ng-template[csNavbarEnd]",
	standalone: true
})
export class NavbarEndDirective {
	constructor() {
	}
}
