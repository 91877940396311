import {HttpErrorResponse} from "@angular/common/http";
import {inject} from "@angular/core";
import {Router} from "@angular/router";
import {AdminService} from "@casavina/data/services/admin.service";
import {AuthService} from "@casavina/utils/api-services/auth.service";
import {WorkerService} from "@casavina/utils/browserapi-services/worker.service";
import {environment} from "@casavina/utils/environments/environment";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {CookieService} from "ngx-cookie-service";
import {catchError, EMPTY, filter, map, of, Subject, switchMap, takeUntil, tap, timer} from "rxjs";
import {AuthApiActions, AuthPageActions} from "./auth.actions";

const destroyRef = new Subject<boolean>();

export const login$ = createEffect(
	(
		action$ = inject(Actions),
		authService = inject(AuthService),
		cookie = inject(CookieService),
		worker = inject(WorkerService),
		router = inject(Router),
		admin = inject(AdminService)
	) =>
		action$.pipe(
			ofType(AuthPageActions.login),
			switchMap((action) =>
				authService.login(action.username, action.password).pipe(
					map(() => {
						const accessToken = cookie.get("access-token");
						const tokenInfo = JSON.parse(atob(accessToken.split(".")[1]));
						cookie.set("subject", tokenInfo.sub, {
							path: "/",
							secure: true
						});
						admin.setAdminSubject(tokenInfo.scope.includes("ROLE_ADMIN"));
						worker.sendMessage({
							url: window.location.protocol + environment.apiUrl
						});
						worker.sendMessage({accessToken: accessToken});
						worker.sendMessage("startRenewInterval");
						router.navigateByUrl("/");
						return AuthApiActions.loginSuccess();
					}),
					catchError((error: HttpErrorResponse) =>
						of(
							AuthApiActions.loginFailure({
								error: error.message
							})
						)
					)
				)
			)
		),
	{functional: true}
);

export const autoLogoutInterval$ = createEffect(
	(action$ = inject(Actions), cookie = inject(CookieService)) =>
		action$.pipe(
			ofType(AuthApiActions.autoLogoutInterval),
			switchMap(() =>
				timer(0, 1000).pipe(
					map(() => cookie.check("access-token")),
					filter((isLogin) => !isLogin),
					map(() => AuthPageActions.logout()),
					takeUntil(destroyRef)
				)
			)
		),
	{functional: true}
);

export const logout$ = createEffect(
	(
		action$ = inject(Actions),
		authService = inject(AuthService),
		cookie = inject(CookieService),
		worker = inject(WorkerService),
		router = inject(Router)
	) =>
		action$.pipe(
			ofType(AuthPageActions.logout),
			switchMap(() =>
				authService.logout().pipe(
					tap(() => logoutHandler(worker, cookie, router)),
					catchError(() => {
						logoutHandler(worker, cookie, router);
						return EMPTY;
					})
				)
			)
		),
	{functional: true, dispatch: false}
);

function logoutHandler(
	worker: WorkerService,
	cookie: CookieService,
	router: Router
) {
	worker.sendMessage("stopRenewInterval");
	cookie.deleteAll("/");
	router.navigateByUrl("/login").then(() => {
		destroyRef.next(true);
	});
}
