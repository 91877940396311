import {createActionGroup, emptyProps, props} from "@ngrx/store";

export const AuthPageActions = createActionGroup({
	source: "Auth Page",
	events: {
		Login: props<{ username: string; password: string }>(),
		Logout: emptyProps()
	}
});

export const AuthApiActions = createActionGroup({
	source: "Auth API",
	events: {
		"Login Success": emptyProps(),
		"Login Failure": props<{ error: string }>(),
		"Auto Logout Interval": emptyProps()
	}
});
