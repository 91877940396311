import {HttpErrorResponse} from "@angular/common/http";
import {inject} from "@angular/core";
import {OfferApiActions, OfferPageActions} from "@casavina/data/store/Offer/offer.actions";
import {OfferService} from "@casavina/utils/api-services/offer.service";
import {ToastService} from "@casavina/utils/services/toast.service";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";

export const loadOffer$ = createEffect(
	(action$ = inject(Actions), offerService = inject(OfferService)) =>
		action$.pipe(
			ofType(OfferPageActions.loadOffer),
			switchMap(() =>
				offerService.getAllOffer().pipe(
					map((offer) =>
						offer.map((item) => ({
							...item,
							createdAt: new Date(item.createdAt),
							expiredAt: new Date(item.expiredAt)
						}))
					),
					map((offer) => OfferApiActions.loadOfferSuccess({offer})),
					catchError((error: HttpErrorResponse) =>
						of(
							OfferApiActions.loadOfferFailure({
								error: error.message
							})
						)
					)
				)
			)
		),
	{functional: true}
);

export const createOffer$ = createEffect(
	(action$ = inject(Actions), offerService = inject(OfferService)) =>
		action$.pipe(
			ofType(OfferPageActions.createOffer),
			switchMap((action) =>
				offerService.createOffer(action.offer).pipe(
					map((offer) => ({
						...offer,
						createdAt: new Date(offer.createdAt),
						expiredAt: new Date(offer.expiredAt)
					})),
					map((offer) => OfferApiActions.createOfferSuccess({offer})),
					catchError((error: HttpErrorResponse) =>
						of(
							OfferApiActions.createOfferFailure({
								error: error.message
							})
						)
					)
				)
			)
		),
	{functional: true}
);

export const createOfferSuccess$ = createEffect(
	(action$ = inject(Actions), messageService = inject(ToastService)) =>
		action$.pipe(
			ofType(OfferApiActions.createOfferSuccess),
			tap(() => {
				messageService.addNewMessage(
					"success",
					"Erfolgreich hinzugefügt",
					"Eine neue Veranstaltung wurde erfolgreich hinzugefügt"
				);
			})
		),
	{functional: true, dispatch: false}
);
export const updateOffer$ = createEffect(
	(action$ = inject(Actions), offerService = inject(OfferService)) =>
		action$.pipe(
			ofType(OfferPageActions.updateOffer),
			switchMap((action) =>
				offerService
					.updateOffer({
						...action.offer,
						expiredAt: new Date(action.offer.expiredAt).toISOString()
					})
					.pipe(
						map(() =>
							OfferApiActions.updateOfferSuccess({offer: action.offer})
						),
						catchError((error: HttpErrorResponse) =>
							of(
								OfferApiActions.updateOfferFailure({
									error: error.message
								})
							)
						)
					)
			)
		),
	{functional: true}
);

export const updateOfferSuccess$ = createEffect(
	(action$ = inject(Actions), messageService = inject(ToastService)) =>
		action$.pipe(
			ofType(OfferApiActions.updateOfferSuccess),
			tap((action) => {
				messageService.addNewMessage(
					"success",
					"Erfolgreich aktualisiert",
					`Die Veranstaltung '${action.offer.title}' wurde erfolgreich aktualisiert`
				);
			})
		),
	{functional: true, dispatch: false}
);

export const deleteOffer$ = createEffect(
	(action$ = inject(Actions), offerService = inject(OfferService)) =>
		action$.pipe(
			ofType(OfferPageActions.deleteOffer),
			switchMap((action) =>
				offerService.deleteOffer(action.id).pipe(
					map(() => OfferApiActions.deleteOfferSuccess({id: action.id})),
					catchError((error: HttpErrorResponse) =>
						of(
							OfferApiActions.deleteOfferFailure({
								error: error.message
							})
						)
					)
				)
			)
		),
	{functional: true}
);
export const deleteOfferSuccess$ = createEffect(
	(action$ = inject(Actions), messageService = inject(ToastService)) =>
		action$.pipe(
			ofType(OfferApiActions.deleteOfferSuccess),
			tap((action) => {
				messageService.addNewMessage(
					"success",
					"Erfolgreich gelöscht",
					`Die Veranstaltung '${action.id}' wurde erfolgreich gelöscht`
				);
			})
		),
	{functional: true, dispatch: false}
);
