import {Injectable} from "@angular/core";

@Injectable({
	providedIn: "root"
})
export class WorkerService {
	private serviceWorker?: ServiceWorker | null;

	constructor() {
		if ("serviceWorker" in navigator) {
			navigator.serviceWorker.ready.then((registration) => {
				this.serviceWorker = registration.active;
			});
		}
	}

	public sendMessage(message: string | Record<string, any>) {
		if (this.serviceWorker) {
			this.serviceWorker.postMessage(message);
		}
	}
}
