import {Allergen, CreateAllergen} from "@casavina/feature/models/allergen";
import {createActionGroup, emptyProps, props} from "@ngrx/store";

export const AllergenPageActions = createActionGroup({
	source: " Core - Allergen Page",
	events: {
		"Load Allergen": emptyProps(),
		"Create Allergen": props<{ allergen: CreateAllergen }>(),
		"Update Allergen": props<{ allergen: Allergen }>(),
		"Delete Allergen": props<{ id: number }>()
	}
});

export const AllergenApiActions = createActionGroup({
	source: "Core - Allergen API",
	events: {
		"Load Allergen Success": props<{ allergen: Allergen[] }>(),
		"Load Allergen Failure": props<{ error: string }>(),
		"Create Allergen Success": props<{ allergen: Allergen }>(),
		"Create Allergen Failure": props<{ error: string }>(),
		"Update Allergen Success": props<{ allergen: Allergen }>(),
		"Update Allergen Failure": props<{ error: string }>(),
		"Delete Allergen Success": props<{ id: number }>(),
		"Delete Allergen Failure": props<{ error: string }>()
	}
});
