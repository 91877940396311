import {HttpErrorResponse} from "@angular/common/http";
import {inject} from "@angular/core";
import {EventApiActions, EventPageActions} from "@casavina/data/store/Event/event.actions";
import {EventService} from "@casavina/utils/api-services/event.service";
import {ToastService} from "@casavina/utils/services/toast.service";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";

export const loadEvent$ = createEffect(
	(action$ = inject(Actions), eventService = inject(EventService)) =>
		action$.pipe(
			ofType(EventPageActions.loadEvent),
			switchMap(() =>
				eventService.getAllEvent().pipe(
					map((event) =>
						event.map((item) => ({
							...item,
							createdAt: new Date(item.createdAt),
							expiredAt: new Date(item.expiredAt)
						}))
					),
					map((event) => EventApiActions.loadEventSuccess({event})),
					catchError((error: HttpErrorResponse) =>
						of(
							EventApiActions.loadEventFailure({
								error: error.message
							})
						)
					)
				)
			)
		),
	{functional: true}
);

export const createEvent$ = createEffect(
	(action$ = inject(Actions), eventService = inject(EventService)) =>
		action$.pipe(
			ofType(EventPageActions.createEvent),
			switchMap((action) =>
				eventService.createEvent(action.event).pipe(
					map((event) => ({
						...event,
						createdAt: new Date(event.createdAt),
						expiredAt: new Date(event.expiredAt)
					})),
					map((event) => EventApiActions.createEventSuccess({event})),
					catchError((error: HttpErrorResponse) =>
						of(
							EventApiActions.createEventFailure({
								error: error.message
							})
						)
					)
				)
			)
		),
	{functional: true}
);

export const createEventSuccess$ = createEffect(
	(action$ = inject(Actions), messageService = inject(ToastService)) =>
		action$.pipe(
			ofType(EventApiActions.createEventSuccess),
			tap(() => {
				messageService.addNewMessage(
					"success",
					"Erfolgreich hinzugefügt",
					"Eine neue Veranstaltung wurde erfolgreich hinzugefügt"
				);
			})
		),
	{functional: true, dispatch: false}
);
export const updateEvent$ = createEffect(
	(action$ = inject(Actions), eventService = inject(EventService)) =>
		action$.pipe(
			ofType(EventPageActions.updateEvent),
			switchMap((action) =>
				eventService
					.updateEvent({
						...action.event,
						expiredAt: new Date(action.event.expiredAt).toISOString()
					})
					.pipe(
						map(() =>
							EventApiActions.updateEventSuccess({event: action.event})
						),
						catchError((error: HttpErrorResponse) =>
							of(
								EventApiActions.updateEventFailure({
									error: error.message
								})
							)
						)
					)
			)
		),
	{functional: true}
);

export const updateEventSuccess$ = createEffect(
	(action$ = inject(Actions), messageService = inject(ToastService)) =>
		action$.pipe(
			ofType(EventApiActions.updateEventSuccess),
			tap((action) => {
				messageService.addNewMessage(
					"success",
					"Erfolgreich aktualisiert",
					`Die Veranstaltung '${action.event.title}' wurde erfolgreich aktualisiert`
				);
			})
		),
	{functional: true, dispatch: false}
);

export const deleteEvent$ = createEffect(
	(action$ = inject(Actions), eventService = inject(EventService)) =>
		action$.pipe(
			ofType(EventPageActions.deleteEvent),
			switchMap((action) =>
				eventService.deleteEvent(action.id).pipe(
					map(() => EventApiActions.deleteEventSuccess({id: action.id})),
					catchError((error: HttpErrorResponse) =>
						of(
							EventApiActions.deleteEventFailure({
								error: error.message
							})
						)
					)
				)
			)
		),
	{functional: true}
);
export const deleteEventSuccess$ = createEffect(
	(action$ = inject(Actions), messageService = inject(ToastService)) =>
		action$.pipe(
			ofType(EventApiActions.deleteEventSuccess),
			tap((action) => {
				messageService.addNewMessage(
					"success",
					"Erfolgreich gelöscht",
					`Die Veranstaltung '${action.id}' wurde erfolgreich gelöscht`
				);
			})
		),
	{functional: true, dispatch: false}
);
