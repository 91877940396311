import {HttpErrorResponse} from "@angular/common/http";
import {inject} from "@angular/core";
import {ReservationApiActions, ReservationPageActions} from "@casavina/data/store/Reservation";
import {ReservationService} from "@casavina/utils/api-services/reservation.service";
import {ToastService} from "@casavina/utils/services/toast.service";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";

export const loadReservations$ = createEffect(
	(
		action$ = inject(Actions),
		reservationService = inject(ReservationService)
	) =>
		action$.pipe(
			ofType(ReservationPageActions.loadReservation),
			switchMap(() =>
				reservationService.getReservationsInRange().pipe(
					map((reservations) =>
						ReservationApiActions.loadReservationSuccess({reservations})
					),
					catchError((error: HttpErrorResponse) =>
						of(
							ReservationApiActions.loadReservationFailure({
								error: JSON.stringify(error.error)
							})
						)
					)
				)
			)
		),
	{functional: true}
);

export const createReservation$ = createEffect(
	(
		action$ = inject(Actions),
		reservationService = inject(ReservationService)
	) =>
		action$.pipe(
			ofType(ReservationPageActions.createReservation),
			switchMap((action) =>
				reservationService.createReservation(action.reservation).pipe(
					map((res) =>
						ReservationApiActions.createReservationSuccess({id: res.id})
					),
					catchError((error: HttpErrorResponse) =>
						of(
							ReservationApiActions.createReservationFailure({
								error: JSON.stringify(error.error)
							})
						)
					)
				)
			)
		),
	{functional: true}
);

export const createReservationSuccess$ = createEffect(
	(action$ = inject(Actions), messageService = inject(ToastService)) =>
		action$.pipe(
			ofType(ReservationApiActions.createReservationSuccess),
			tap(() =>
				messageService.addNewMessage(
					"success",
					"Ihre Reservierung wurde erfolgreich geschickt",
					"Vielen Dank für Ihre Reservierung. Wir freuen uns darauf, Sie bald zu sehen!"
				)
			)
		),
	{functional: true, dispatch: false}
);

export const createReservationFailure$ = createEffect(
	(action$ = inject(Actions), messageService = inject(ToastService)) =>
		action$.pipe(
			ofType(ReservationApiActions.createReservationFailure),
			tap(() =>
				messageService.addNewMessage(
					"error",
					"Ein Problem ist aufgetreten",
					"Bei Ihrer Reservierung ist ein Problem aufgetreten. Bitte versuchen Sie es erneut."
				)
			)
		),
	{functional: true, dispatch: false}
);
export const updateReservation$ = createEffect(
	(
		action$ = inject(Actions),
		reservationService = inject(ReservationService)
	) =>
		action$.pipe(
			ofType(ReservationPageActions.toggleReservationStatus),
			switchMap((action) =>
				reservationService.updateReservation(action.reservation).pipe(
					map(() =>
						ReservationApiActions.toggleReservationStatusSuccess({
							reservation: action.reservation
						})
					),
					catchError((error: HttpErrorResponse) =>
						of(
							ReservationApiActions.toggleReservationStatusFailure({
								error: JSON.stringify(error.error)
							})
						)
					)
				)
			)
		),
	{functional: true}
);
