import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable({
	providedIn: "root"
})
export class AdminService {
	private adminSubject = new BehaviorSubject<boolean>(false);

	public setAdminSubject(isAdmin: boolean) {
		this.adminSubject.next(isAdmin);
	}

	public getAdminSubject() {
		return this.adminSubject.asObservable();
	}
}
