import {EventApiActions, EventPageActions} from "@casavina/data/store/Event/event.actions";
import {Event} from "@casavina/feature/models/event";
import {createEntityAdapter, EntityState} from "@ngrx/entity";
import {createFeature, createReducer, createSelector, on} from "@ngrx/store";

export interface EventState extends EntityState<Event> {
	error: string;
	status: "pending" | "loading" | "success" | "error";
}

export const eventAdapter = createEntityAdapter<Event>();

const initialState: EventState = eventAdapter.getInitialState({
	error: "",
	status: "pending"
});

export const eventFeature = createFeature({
	name: "event",
	reducer: createReducer(
		initialState,
		on(EventPageActions.loadEvent, (state) => ({
			...state,
			status: "loading"
		})),
		on(EventApiActions.loadEventSuccess, (state, {event}) =>
			eventAdapter.setAll(event, {
				...state,
				error: "",
				status: "success"
			})
		),
		on(EventApiActions.loadEventFailure, (state, {error}) => ({
			...state,
			error: error,
			status: "error"
		})),
		on(EventPageActions.createEvent, (state) => ({
			...state,
			status: "loading"
		})),
		on(EventApiActions.createEventSuccess, (state, {event}) =>
			eventAdapter.setOne(event, {
				...state,
				error: "",
				status: "success"
			})
		),
		on(EventApiActions.createEventFailure, (state, {error}) => ({
			...state,
			error: error,
			status: "error"
		})),
		on(EventPageActions.updateEvent, (state) => ({
			...state,
			status: "loading"
		})),
		on(EventApiActions.updateEventSuccess, (state, {event}) =>
			eventAdapter.upsertOne(event, {
				...state,
				error: "",
				status: "success"
			})
		),
		on(EventApiActions.updateEventFailure, (state, {error}) => ({
			...state,
			error: error,
			status: "error"
		})),
		on(EventPageActions.deleteEvent, (state) => ({
			...state,
			status: "loading"
		})),
		on(EventApiActions.deleteEventSuccess, (state, {id}) =>
			eventAdapter.removeOne(id, {
				...state,
				error: "",
				status: "success"
			})
		),
		on(EventApiActions.deleteEventFailure, (state, {error}) => ({
			...state,
			error: error,
			status: "error"
		}))
	),
	extraSelectors: ({selectEntities, selectIds}) => ({
		selectAll: createSelector(
			selectEntities,
			selectIds,
			(eventEntities, eventIds) =>
				eventIds
					.map((id) => eventEntities[id])
					.filter((event): event is Event => event !== undefined)
		)
	})
});
