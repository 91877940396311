import {inject} from "@angular/core";
import {Routes} from "@angular/router";
import {FacadeService} from "@casavina/utils/facade-store/facade.service";
import {authGuard} from "@casavina/utils/guards/auth.guard";
import {nonAuthGuard} from "@casavina/utils/guards/non-auth.guard";

export const APP_ROUTES: Routes = [
	{
		path: "",
		loadChildren: () => import("./modules/routes.modules"),
		canActivate: [authGuard],
		resolve: {
			autoLogoutInterval: () => inject(FacadeService).autoLogoutInterval()
		}
	},
	{
		path: "login",
		title: "Casa Vina - Anmelden",
		loadComponent: () =>
			import("./login/login.component").then((c) => c.LoginComponent),
		canActivate: [nonAuthGuard]
	},
	{
		path: "**",
		title: "Casa Vina - Page Not Found",
		loadComponent: () =>
			import("@casavina/ui/page-not-found/page-not-found.component").then(
				(c) => c.PageNotFoundComponent
			)
	}
];
