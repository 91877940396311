import {Category} from "@casavina/feature/models/category";
import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {createFeature, createReducer, createSelector, on} from "@ngrx/store";
import {CategoryApiActions, CategoryPageActions} from "./category.actions";

interface CategoryState extends EntityState<Category> {
	error: string;
	status: "pending" | "loading" | "success" | "error";
}

const categoryAdapter: EntityAdapter<Category> = createEntityAdapter<Category>();

const initialState: CategoryState = categoryAdapter.getInitialState({
	error: "",
	status: "pending"
});

export const categoryFeature = createFeature({
	name: "category",
	reducer: createReducer(
		initialState,
		on(CategoryPageActions.loadCategory, (state) => ({
			...state,
			status: "loading"
		})),
		on(CategoryApiActions.loadCategorySuccess, (state, {categories}) =>
			categoryAdapter.setAll(categories, {
				...state,
				error: "",
				status: "success"
			})
		),
		on(CategoryApiActions.loadCategoryFailure, (state, {error}) => ({
			...state,
			error: error,
			status: "error"
		})),
		on(CategoryPageActions.deleteCategory, (state) => ({
			...state,
			status: "loading"
		})),
		on(CategoryApiActions.deleteCategorySuccess, (state, {id}) =>
			categoryAdapter.removeOne(id, {
				...state,
				error: "",
				status: "success"
			})
		),
		on(CategoryApiActions.deleteCategoryFailure, (state, {error}) => ({
			...state,
			error: error,
			status: "error"
		})),
		on(CategoryPageActions.createCategory, (state) => ({
			...state,
			status: "loading"
		})),
		on(CategoryApiActions.createCategorySuccess, (state, {category}) =>
			categoryAdapter.addOne(category, {
				...state,
				error: "",
				status: "success"
			})
		),
		on(CategoryApiActions.createCategoryFailure, (state, {error}) => ({
			...state,
			error: error,
			status: "error"
		})),
		on(CategoryPageActions.updateCategory, (state) => ({
			...state,
			status: "loading"
		})),
		on(CategoryApiActions.updateCategorySuccess, (state, {category}) =>
			categoryAdapter.upsertOne(category, {
				...state,
				error: "",
				status: "success"
			})
		),
		on(CategoryApiActions.updateCategoryFailure, (state, {error}) => ({
			...state,
			error: error,
			status: "error"
		})),
		on(CategoryPageActions.localUpdateCategory, (state, {category}) =>
			categoryAdapter.upsertOne(category, {
				...state,
				error: "",
				status: "success"
			})
		)
	),
	extraSelectors: ({selectEntities, selectIds}) => ({
		selectAll: createSelector(
			selectEntities,
			selectIds,
			(categoryEntities, categoryIds) =>
				categoryIds
					.map((id) => categoryEntities[id])
					.filter((category): category is Category => category !== undefined)
		),
		selectCategoryById: (id: number) =>
			createSelector(
				selectEntities,
				(categoryEntities) => categoryEntities[id]
			)
	})
});
