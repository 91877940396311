import {AuthApiActions, AuthPageActions} from "@casavina/data/store/Auth/auth.actions";
import {createFeature, createReducer, on} from "@ngrx/store";

export interface AuthState {
	status: "pending" | "loading" | "success" | "error";
	error: string;
}

const initialState: AuthState = {
	status: "pending",
	error: ""
};

export const authFeature = createFeature({
	name: "auth",
	reducer: createReducer(
		initialState,
		on(AuthPageActions.login, (state) => ({...state, status: "loading"})),
		on(AuthApiActions.loginSuccess, (state) => {
			return {
				...state,
				error: "",
				status: "success"
			};
		}),
		on(AuthApiActions.loginFailure, (state, {error}) => ({
			...state,
			status: "error",
			error: error
		})),
		on(AuthPageActions.logout, (state) => ({
			...state,
			status: "success",
			error: ""
		}))
	)
});
