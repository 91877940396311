import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {CreateEvent, Event} from "@casavina/feature/models/event";
import {environment} from "@casavina/utils/environments/environment";
import {Observable} from "rxjs";

@Injectable({
	providedIn: "root"
})
export class EventService {
	private readonly apiUrl: string = environment.apiUrl;
	private httpOptions = {
		headers: new HttpHeaders({
			"Content-Type": "application/json"
		}),
		withCredentials: true
	};

	constructor(private http: HttpClient) {
	}

	public getAllEvent(): Observable<Event[]> {
		return this.http.get<Event[]>(`${this.apiUrl}/v1/events`, this.httpOptions);
	}

	public createEvent(event: CreateEvent): Observable<Event> {
		return this.http.post<Event>(
			`${this.apiUrl}/v1/events`,
			event,
			this.httpOptions
		);
	}

	public updateEvent(event: Event): Observable<void> {
		return this.http.put<void>(
			`${this.apiUrl}/v1/events`,
			event,
			this.httpOptions
		);
	}

	public deleteEvent(id: string): Observable<void> {
		return this.http.delete<void>(
			`${this.apiUrl}/v1/events/${id}`,
			this.httpOptions
		);
	}
}
