import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {ChangePassword, CreateUser, Users} from "@casavina/feature/models/users";
import {environment} from "@casavina/utils/environments/environment";
import {Observable} from "rxjs";

@Injectable({
	providedIn: "root"
})
export class UserService {
	private readonly apiUrl: string = environment.apiUrl;
	private httpOptions = {
		headers: new HttpHeaders({
			"Content-Type": "application/json"
		}),
		withCredentials: true
	};

	constructor(private http: HttpClient) {
	}

	public getAllUsers(): Observable<Users[]> {
		return this.http.get<Users[]>(`${this.apiUrl}/v1/users`, this.httpOptions);
	}

	public createUser(user: CreateUser): Observable<void> {
		return this.http.post<void>(
			`${this.apiUrl}/v1/users`,
			user,
			this.httpOptions
		);
	}

	public changePassword(changePassword: ChangePassword): Observable<void> {
		return this.http.patch<void>(
			`${this.apiUrl}/v1/users`,
			changePassword,
			this.httpOptions
		);
	}

	public deleteUser(username: string): Observable<void> {
		return this.http.delete<void>(
			`${this.apiUrl}/v1/users/${username}`,
			this.httpOptions
		);
	}
}
