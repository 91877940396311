import {Injectable} from "@angular/core";
import {AdminService} from "@casavina/data/services/admin.service";
import {AuthApiActions, authFeature, AuthPageActions} from "@casavina/data/store/Auth";
import {CorePageActions} from "@casavina/data/store/Core/core.actions";
import {eventFeature, EventPageActions} from "@casavina/data/store/Event";
import {offerFeature, OfferPageActions} from "@casavina/data/store/Offer";
import {reservationFeature, ReservationPageActions} from "@casavina/data/store/Reservation";
import {usersFeature, UsersPageActions} from "@casavina/data/store/Users";
import {Allergen, CreateAllergen} from "@casavina/feature/models/allergen";
import {Category, CreateCategory} from "@casavina/feature/models/category";
import {CreateEvent, Event} from "@casavina/feature/models/event";
import {CreateIngredient, Ingredient} from "@casavina/feature/models/ingredient";
import {CreateMenu, Menu, MenuDetails} from "@casavina/feature/models/menu";
import {CreateOffer, Offer} from "@casavina/feature/models/offer";
import {CreateReservation, Reservation} from "@casavina/feature/models/reservation";
import {ChangePassword, CreateUser, Users} from "@casavina/feature/models/users";
import {Dictionary} from "@ngrx/entity";
import {Store} from "@ngrx/store";
import {CookieService} from "ngx-cookie-service";
import {allergenFeature, AllergenPageActions} from "projects/library/src/lib/data-access/store/Core/Allergen";
import {categoryFeature, CategoryPageActions} from "projects/library/src/lib/data-access/store/Core/Category";
import {ingredientFeature, IngredientPageActions} from "projects/library/src/lib/data-access/store/Core/Ingredient";
import {menuFeature, MenuPageActions} from "projects/library/src/lib/data-access/store/Core/Menu";
import {Observable, of} from "rxjs";

@Injectable({
	providedIn: "root"
})
export class FacadeService {
	constructor(
		private store: Store,
		private cookieService: CookieService,
		private adminService: AdminService
	) {
	}

	public loadCoreState(): void {
		this.store.dispatch(CorePageActions.loadCore());
	}

	public loadEventState(): void {
		this.store.dispatch(EventPageActions.loadEvent());
	}

	public loadOfferState(): void {
		this.store.dispatch(OfferPageActions.loadOffer());
	}

	public loadReservationState(): void {
		this.store.dispatch(ReservationPageActions.loadReservation());
	}

	public getAllAllergen(): Observable<Allergen[]> {
		return this.store.select(allergenFeature.selectAll);
	}

	public getAllAllergenOrderByShortName(): Observable<Allergen[]> {
		return this.store.select(allergenFeature.selectAllOrderByShortName);
	}

	public createAllergen(allergen: CreateAllergen): void {
		this.store.dispatch(AllergenPageActions.createAllergen({allergen}));
	}

	public updateAllergen(allergen: Allergen): void {
		this.store.dispatch(AllergenPageActions.updateAllergen({allergen}));
	}

	public deleteAllergen(id: number): void {
		this.store.dispatch(AllergenPageActions.deleteAllergen({id}));
	}

	public getAllergenStatus(): Observable<string> {
		return this.store.select(allergenFeature.selectStatus);
	}

	public getAllergenError(): Observable<string> {
		return this.store.select(allergenFeature.selectError);
	}

	public login(username: string, password: string): void {
		this.store.dispatch(
			AuthPageActions.login({username: username, password: password})
		);
	}

	public autoLogoutInterval() {
		this.store.dispatch(AuthApiActions.autoLogoutInterval());
	}

	public logout(): void {
		this.store.dispatch(AuthPageActions.logout());
	}

	public isLogin(): Observable<boolean> {
		return of(this.cookieService.check("access-token"));
	}

	public getAuthStatus(): Observable<string> {
		return this.store.select(authFeature.selectStatus);
	}

	public getAuthError(): Observable<string> {
		return this.store.select(authFeature.selectError);
	}

	public getAllCategory(): Observable<Category[]> {
		return this.store.select(categoryFeature.selectAll);
	}

	public getCategoryEntities(): Observable<Dictionary<Category>> {
		return this.store.select(categoryFeature.selectEntities);
	}

	public createCategory(category: CreateCategory): void {
		this.store.dispatch(CategoryPageActions.createCategory({category}));
	}

	public deleteCategory(id: number): void {
		this.store.dispatch(CategoryPageActions.deleteCategory({id}));
	}

	public getCategoryStatus(): Observable<string> {
		return this.store.select(categoryFeature.selectStatus);
	}

	public getCategoryError(): Observable<string> {
		return this.store.select(categoryFeature.selectError);
	}

	public getAllEvent(): Observable<Event[]> {
		return this.store.select(eventFeature.selectAll);
	}

	public createEvent(event: CreateEvent): void {
		this.store.dispatch(EventPageActions.createEvent({event}));
	}

	public updateEvent(event: Event): void {
		this.store.dispatch(EventPageActions.updateEvent({event}));
	}

	public deleteEvent(id: string): void {
		this.store.dispatch(EventPageActions.deleteEvent({id}));
	}

	public getEventStatus(): Observable<string> {
		return this.store.select(eventFeature.selectStatus);
	}

	public getEventError(): Observable<string> {
		return this.store.select(eventFeature.selectError);
	}

	public getAllOffer(): Observable<Offer[]> {
		return this.store.select(offerFeature.selectAll);
	}

	public createOffer(offer: CreateOffer): void {
		this.store.dispatch(OfferPageActions.createOffer({offer}));
	}

	public updateOffer(offer: Offer): void {
		this.store.dispatch(OfferPageActions.updateOffer({offer}));
	}

	public deleteOffer(id: string): void {
		this.store.dispatch(OfferPageActions.deleteOffer({id}));
	}

	public getOfferStatus(): Observable<string> {
		return this.store.select(offerFeature.selectStatus);
	}

	public getOfferError(): Observable<string> {
		return this.store.select(offerFeature.selectError);
	}

	public getAllIngredient(): Observable<Ingredient[]> {
		return this.store.select(ingredientFeature.selectAll);
	}

	public createIngredient(ingredient: CreateIngredient): void {
		this.store.dispatch(IngredientPageActions.createIngredient({ingredient}));
	}

	public deleteIngredient(id: number): void {
		this.store.dispatch(IngredientPageActions.deleteIngredient({id}));
	}

	public updateIngredient(ingredient: Ingredient): void {
		this.store.dispatch(IngredientPageActions.updateIngredient({ingredient}));
	}

	public getIngredientStatus(): Observable<string> {
		return this.store.select(ingredientFeature.selectStatus);
	}

	public getIngredientError(): Observable<string> {
		return this.store.select(ingredientFeature.selectError);
	}

	public getMenuById(id: number): Observable<Menu | undefined> {
		return this.store.select(menuFeature.selectMenuById(id));
	}

	public getMenuDetailsById(id: number): Observable<MenuDetails | undefined> {
		return this.store.select(menuFeature.selectMenuDetailsById(id));
	}

	public createMenu(menu: CreateMenu): void {
		this.store.dispatch(MenuPageActions.createMenu({menu}));
	}

	public updateMenu(menu: CreateMenu, id: number): void {
		this.store.dispatch(MenuPageActions.updateMenu({menu, id}));
	}

	public deleteMenu(id: number): void {
		this.store.dispatch(MenuPageActions.deleteMenu({id}));
	}

	public getMenuStatus(): Observable<string> {
		return this.store.select(menuFeature.selectStatus);
	}

	public getMenuError(): Observable<string> {
		return this.store.select(menuFeature.selectError);
	}

	public getAllReservation(): Observable<Reservation[]> {
		return this.store.select(reservationFeature.selectAll);
	}

	public createReservation(reservation: CreateReservation): void {
		this.store.dispatch(
			ReservationPageActions.createReservation({reservation})
		);
	}

	public toggleReservationStatus(reservation: Reservation): void {
		this.store.dispatch(
			ReservationPageActions.toggleReservationStatus({reservation})
		);
	}

	public getReservationStatus(): Observable<string> {
		return this.store.select(reservationFeature.selectStatus);
	}

	public getReservationError(): Observable<string> {
		return this.store.select(reservationFeature.selectError);
	}

	public loadUsers(): void {
		this.store.dispatch(UsersPageActions.loadUsers());
	}

	public getAllUsers(): Observable<Users[]> {
		return this.store.select(usersFeature.selectUsers);
	}

	public createUser(user: CreateUser): void {
		this.store.dispatch(UsersPageActions.createUser({user}));
	}

	public changePassword(changePassword: ChangePassword): void {
		this.store.dispatch(UsersPageActions.changePassword({changePassword}));
	}

	public deleteUser(username: string): void {
		this.store.dispatch(UsersPageActions.deleteUser({username}));
	}

	public getUserStatus(): Observable<string> {
		return this.store.select(usersFeature.selectStatus);
	}

	public getUserError(): Observable<string> {
		return this.store.select(usersFeature.selectError);
	}

	public isAdmin(): Observable<boolean> {
		return this.adminService.getAdminSubject();
	}

	public setAdmin(admin: boolean): void {
		this.adminService.setAdminSubject(admin);
	}
}
