import {AsyncPipe, NgIf, NgTemplateOutlet} from "@angular/common";
import {ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef} from "@angular/core";
import {RouterLink} from "@angular/router";
import {NavbarItemsMobileComponent} from "@casavina/ui/navbar/ui/navbar-items-mobile/navbar-items-mobile.component";
import {NavbarItemsComponent} from "@casavina/ui/navbar/ui/navbar-items/navbar-items.component";
import {NavbarEndDirective} from "@casavina/ui/navbar/utils/navbar-end.directive";
import {NavbarItem} from "./models/navbar-item";

@Component({
	selector: "casavina-navbar",
	standalone: true,
	imports: [
		NavbarItemsComponent,
		NgTemplateOutlet,
		NgIf,
		AsyncPipe,
		NavbarItemsMobileComponent,
		RouterLink
	],
	templateUrl: "./navbar.component.html",
	styleUrls: ["./navbar.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent {
	@Input({required: true}) public navbarItems: NavbarItem[] = [];
	@Input() public logo: string = "";
	@ContentChild(NavbarEndDirective, {read: TemplateRef})
	public navbarEndTemplate?: TemplateRef<any>;

	constructor() {
	}
}
