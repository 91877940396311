import {CreateOffer, Offer} from "@casavina/feature/models/offer";
import {createActionGroup, emptyProps, props} from "@ngrx/store";

export const OfferPageActions = createActionGroup({
	source: "Offer Page",
	events: {
		"Load Offer": emptyProps(),
		"Create Offer": props<{ offer: CreateOffer }>(),
		"Update Offer": props<{ offer: Offer }>(),
		"Delete Offer": props<{ id: string }>()
	}
});

export const OfferApiActions = createActionGroup({
	source: "Offer API",
	events: {
		"Load Offer Success": props<{ offer: Offer[] }>(),
		"Load Offer Failure": props<{ error: string }>(),
		"Create Offer Success": props<{ offer: Offer }>(),
		"Create Offer Failure": props<{ error: string }>(),
		"Update Offer Success": props<{ offer: Offer }>(),
		"Update Offer Failure": props<{ error: string }>(),
		"Delete Offer Success": props<{ id: string }>(),
		"Delete Offer Failure": props<{ error: string }>()
	}
});
