import {CreateEvent, Event} from "@casavina/feature/models/event";
import {createActionGroup, emptyProps, props} from "@ngrx/store";

export const EventPageActions = createActionGroup({
	source: "Event Page",
	events: {
		"Load Event": emptyProps(),
		"Create Event": props<{ event: CreateEvent }>(),
		"Update Event": props<{ event: Event }>(),
		"Delete Event": props<{ id: string }>()
	}
});

export const EventApiActions = createActionGroup({
	source: "Event API",
	events: {
		"Load Event Success": props<{ event: Event[] }>(),
		"Load Event Failure": props<{ error: string }>(),
		"Create Event Success": props<{ event: Event }>(),
		"Create Event Failure": props<{ error: string }>(),
		"Update Event Success": props<{ event: Event }>(),
		"Update Event Failure": props<{ error: string }>(),
		"Delete Event Success": props<{ id: string }>(),
		"Delete Event Failure": props<{ error: string }>()
	}
});
