<div class = "flex-wrapper" >
	<casavina-navbar *ngIf = "facadeService.isLogin() | async"
	                 [logo] = "'assets/logo.png'"
	                 [navbarItems] = "navbarItems" >
		<ng-template csNavbarEnd >
			<button (click) = "facadeService.logout()" color = "primary" mat-stroked-button >
				<mat-icon fontSet = "material-symbols-outlined" >logout</mat-icon >
				Abmelden
			</button >
		</ng-template >
	</casavina-navbar >
	<div class = "content" >
		<router-outlet />
	</div >
</div >
<p-toast [breakpoints] = "{ '599.98px': { width: '100%', right: '0', left: '0' } }"
         [life] = "5000"
         [preventOpenDuplicates] = "true" />
<p-confirmDialog rejectButtonStyleClass = "p-button-text p-button-plain" />
