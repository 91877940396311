<!--suppress AngularNgOptimizedImage -->
<nav >
	<div class = "navbar-content" >
		<div class = "navbar-content-logo" >
			<a routerLink = "/" style = "margin-right: 20px" >
				<img [src] = "logo" alt = "logo" class = "logo" />
			</a >
			<casavina-navbar-items-mobile [navbarItems] = "navbarItems" />
		</div >
		<casavina-navbar-items [navbarItems] = "navbarItems" />
		<ng-container *ngTemplateOutlet = "navbarEndTemplate || defaultEnd" ></ng-container >
	</div >
</nav >

<ng-template #defaultEnd ></ng-template >
