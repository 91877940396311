import {NgForOf, NgIf} from "@angular/common";
import {ChangeDetectionStrategy, Component, Input} from "@angular/core";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatListModule} from "@angular/material/list";
import {MatMenuModule} from "@angular/material/menu";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {NavbarItem} from "../../models/navbar-item";

@Component({
	selector: "casavina-navbar-items-mobile",
	standalone: true,
	imports: [
		MatButtonModule,
		MatIconModule,
		MatMenuModule,
		NgForOf,
		RouterLinkActive,
		RouterLink,
		MatListModule,
		NgIf
	],
	templateUrl: "./navbar-items-mobile.component.html",
	styleUrls: ["./navbar-items-mobile.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarItemsMobileComponent {
	@Input({required: true}) public navbarItems: NavbarItem[] = [];
}
