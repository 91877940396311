import {HttpErrorResponse} from "@angular/common/http";
import {inject} from "@angular/core";
import {CoreService} from "@casavina/utils/api-services/core.service";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap} from "rxjs";
import {CorePageActions} from "../core.actions";
import {CategoryApiActions, CategoryPageActions} from "./category.actions";

export const loadCategories$ = createEffect(
	(action$ = inject(Actions), coreService = inject(CoreService)) =>
		action$.pipe(
			ofType(CategoryPageActions.loadCategory, CorePageActions.loadCore),
			switchMap(() =>
				coreService.getAllCategories().pipe(
					map((categories) =>
						CategoryApiActions.loadCategorySuccess({categories})
					),
					catchError((error: HttpErrorResponse) =>
						of(
							CategoryApiActions.loadCategoryFailure({
								error: error.message
							})
						)
					)
				)
			)
		),
	{functional: true}
);

export const deleteCategories$ = createEffect(
	(action$ = inject(Actions), coreService = inject(CoreService)) =>
		action$.pipe(
			ofType(CategoryPageActions.deleteCategory),
			switchMap((action) =>
				coreService.deleteCategoryById(action.id).pipe(
					map(() =>
						CategoryApiActions.deleteCategorySuccess({id: action.id})
					),
					catchError((error: HttpErrorResponse) =>
						of(
							CategoryApiActions.deleteCategoryFailure({
								error: error.message
							})
						)
					)
				)
			)
		),
	{functional: true}
);

export const createCategory$ = createEffect(
	(action$ = inject(Actions), coreService = inject(CoreService)) =>
		action$.pipe(
			ofType(CategoryPageActions.createCategory),
			switchMap((action) =>
				coreService.createCategory(action.category).pipe(
					map((res) =>
						CategoryApiActions.createCategorySuccess({
							category: {
								...action.category,
								id: res.id,
								menus: []
							}
						})
					),
					catchError((error: HttpErrorResponse) =>
						of(
							CategoryApiActions.createCategoryFailure({
								error: error.message
							})
						)
					)
				)
			)
		),
	{functional: true}
);
