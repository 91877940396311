import {OfferApiActions, OfferPageActions} from "@casavina/data/store/Offer/offer.actions";
import {Offer} from "@casavina/feature/models/offer";
import {createEntityAdapter, EntityState} from "@ngrx/entity";
import {createFeature, createReducer, createSelector, on} from "@ngrx/store";

export interface OfferState extends EntityState<Offer> {
	error: string;
	status: "pending" | "loading" | "success" | "error";
}

export const offerAdapter = createEntityAdapter<Offer>();

const initialState: OfferState = offerAdapter.getInitialState({
	error: "",
	status: "pending"
});

export const offerFeature = createFeature({
	name: "offer",
	reducer: createReducer(
		initialState,
		on(OfferPageActions.loadOffer, (state) => ({
			...state,
			status: "loading"
		})),
		on(OfferApiActions.loadOfferSuccess, (state, {offer}) =>
			offerAdapter.setAll(offer, {
				...state,
				error: "",
				status: "success"
			})
		),
		on(OfferApiActions.loadOfferFailure, (state, {error}) => ({
			...state,
			error: error,
			status: "error"
		})),
		on(OfferPageActions.createOffer, (state) => ({
			...state,
			status: "loading"
		})),
		on(OfferApiActions.createOfferSuccess, (state, {offer}) =>
			offerAdapter.setOne(offer, {
				...state,
				error: "",
				status: "success"
			})
		),
		on(OfferApiActions.createOfferFailure, (state, {error}) => ({
			...state,
			error: error,
			status: "error"
		})),
		on(OfferPageActions.updateOffer, (state) => ({
			...state,
			status: "loading"
		})),
		on(OfferApiActions.updateOfferSuccess, (state, {offer}) =>
			offerAdapter.upsertOne(offer, {
				...state,
				error: "",
				status: "success"
			})
		),
		on(OfferApiActions.updateOfferFailure, (state, {error}) => ({
			...state,
			error: error,
			status: "error"
		})),
		on(OfferPageActions.deleteOffer, (state) => ({
			...state,
			status: "loading"
		})),
		on(OfferApiActions.deleteOfferSuccess, (state, {id}) =>
			offerAdapter.removeOne(id, {
				...state,
				error: "",
				status: "success"
			})
		),
		on(OfferApiActions.deleteOfferFailure, (state, {error}) => ({
			...state,
			error: error,
			status: "error"
		}))
	),
	extraSelectors: ({selectEntities, selectIds}) => ({
		selectAll: createSelector(
			selectEntities,
			selectIds,
			(offerEntities, offerIds) =>
				offerIds
					.map((id) => offerEntities[id])
					.filter((offer): offer is Offer => offer !== undefined)
		)
	})
});
