import {Menu, MenuDetails} from "@casavina/feature/models/menu";
import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {createFeature, createReducer, createSelector, on} from "@ngrx/store";
import {allergenFeature} from "../Allergen";
import {ingredientFeature} from "../Ingredient";
import {MenuApiActions, MenuPageActions} from "./menu.actions";

interface MenuState extends EntityState<Menu> {
	favorite: number[];
	error: string;
	status: "pending" | "loading" | "success" | "error";
}

const menuAdapter: EntityAdapter<Menu> = createEntityAdapter<Menu>();

const initialState: MenuState = menuAdapter.getInitialState({
	favorite: [],
	error: "",
	status: "pending"
});

export const menuFeature = createFeature({
	name: "menu",
	reducer: createReducer(
		initialState,
		on(MenuPageActions.loadMenu, (state) => ({...state, status: "loading"})),
		on(MenuApiActions.loadMenuSuccess, (state, {menu}) =>
			menuAdapter.setAll(menu, {
				...state,
				error: "",
				status: "success"
			})
		),
		on(MenuApiActions.loadMenuFailure, (state, {error}) => ({
			...state,
			error: error,
			status: "error"
		})),
		on(MenuPageActions.createMenu, (state) => ({
			...state,
			status: "loading"
		})),
		on(MenuApiActions.createMenuSuccess, (state, {menu}) =>
			menuAdapter.addOne(menu, {
				...state,
				error: "",
				status: "success"
			})
		),
		on(MenuApiActions.createMenuFailure, (state, {error}) => ({
			...state,
			error: error,
			status: "error"
		})),
		on(MenuPageActions.updateMenu, (state) => ({
			...state,
			status: "loading"
		})),
		on(MenuApiActions.updateMenuSuccess, (state, {menu}) =>
			menuAdapter.upsertOne(menu, {
				...state,
				error: "",
				status: "success"
			})
		),
		on(MenuApiActions.updateMenuFailure, (state, {error}) => ({
			...state,
			error: error,
			status: "error"
		})),
		on(MenuPageActions.deleteMenu, (state) => ({
			...state,
			status: "loading"
		})),
		on(MenuApiActions.deleteMenuSuccess, (state, {id}) =>
			menuAdapter.removeOne(id, {
				...state,
				error: "",
				status: "success"
			})
		),
		on(MenuApiActions.deleteMenuFailure, (state, {error}) => ({
			...state,
			error: error,
			status: "error"
		})),
		on(MenuPageActions.localUpdateMenu, (state, {menu}) =>
			menuAdapter.upsertOne(menu, {
				...state,
				error: "",
				status: "success"
			})
		)
	),
	extraSelectors: ({selectEntities}) => ({
		selectMenuById: (id: number) =>
			createSelector(selectEntities, (menuEntities) => menuEntities[id]),
		selectMenuDetailsById: (id: number) =>
			createSelector(
				selectEntities,
				allergenFeature.selectEntities,
				ingredientFeature.selectEntities,
				(menuEntities, allergenEntities, ingredientEntities) => {
					const menu: Menu | undefined = menuEntities[id];
					if (menu) {
						const menuDetails: MenuDetails = {
							...menu,
							allergens: menu.allergens.map((id) => allergenEntities[id]),
							ingredients: menu.ingredients.map((id) => ingredientEntities[id])
						};
						return menuDetails;
					}
					return undefined;
				}
			)
	})
});
