import {registerLocaleData} from "@angular/common";
import {provideHttpClient, withInterceptors} from "@angular/common/http";
import localeDe from "@angular/common/locales/de";
import {enableProdMode, LOCALE_ID, provideZoneChangeDetection} from "@angular/core";
import {bootstrapApplication} from "@angular/platform-browser";
import {provideAnimations} from "@angular/platform-browser/animations";
import {provideRouter} from "@angular/router";
import {provideServiceWorker} from "@angular/service-worker";
import {AuthEffects, authFeature} from "@casavina/data/store/Auth";
import {environment} from "@casavina/utils/environments/environment";
import {authInterceptor} from "@casavina/utils/interceptors/auth-interceptor/auth.interceptor";
import {provideEffects} from "@ngrx/effects";
import {provideState, provideStore} from "@ngrx/store";
import {ConfirmationService, MessageService} from "primeng/api";
import {DialogService} from "primeng/dynamicdialog";
import {AppComponent} from "./app/app.component";
import {APP_ROUTES} from "./app/routes";

registerLocaleData(localeDe);

if (environment.production) {
	enableProdMode();
}

bootstrapApplication(AppComponent, {
	providers: [
		provideZoneChangeDetection({eventCoalescing: true}),
		provideRouter(APP_ROUTES),
		provideAnimations(),
		provideHttpClient(withInterceptors([authInterceptor])),
		provideStore(),
		provideState(authFeature),
		provideEffects(AuthEffects),
		environment.providers,
		provideServiceWorker("service-worker.js", {
			enabled: environment.production,
			registrationStrategy: "registerWhenStable:30000"
		}),
		{
			provide: LOCALE_ID,
			useValue: "de"
		},
		MessageService,
		DialogService,
		ConfirmationService
	]
}).catch((err) => console.error(err));
