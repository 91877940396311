import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Allergen, CreateAllergen} from "@casavina/feature/models/allergen";
import {Category, CreateCategory} from "@casavina/feature/models/category";
import {CreateIngredient, Ingredient} from "@casavina/feature/models/ingredient";
import {CreateMenu, Menu} from "@casavina/feature/models/menu";
import {environment} from "@casavina/utils/environments/environment";
import {Observable} from "rxjs";

@Injectable({
	providedIn: "root"
})
export class CoreService {
	private readonly apiUrl: string = environment.apiUrl;
	private httpOptions = {
		headers: new HttpHeaders({
			"Content-Type": "application/json"
		}),
		withCredentials: true
	};

	constructor(private http: HttpClient) {
	}

	public getAllCategories(): Observable<Category[]> {
		return this.http.get<Category[]>(
			`${this.apiUrl}/v1/categories`,
			this.httpOptions
		);
	}

	public createCategory(category: CreateCategory): Observable<{ id: number }> {
		return this.http.post<{ id: number }>(
			`${this.apiUrl}/v1/categories`,
			category,
			this.httpOptions
		);
	}

	public deleteCategoryById(id: number): Observable<void> {
		return this.http.delete<void>(`${this.apiUrl}/v1/categories/${id}`);
	}

	public getAllMenus(): Observable<Menu[]> {
		return this.http.get<Menu[]>(`${this.apiUrl}/v1/menus`, this.httpOptions);
	}

	public deleteMenuById(id: number): Observable<void> {
		return this.http.delete<void>(
			`${this.apiUrl}/v1/menus/${id}`,
			this.httpOptions
		);
	}

	public createMenuItem(menu: CreateMenu): Observable<{ id: number }> {
		return this.http.post<{ id: number }>(
			`${this.apiUrl}/v1/menus`,
			menu,
			this.httpOptions
		);
	}

	public updateMenuItem(menu: CreateMenu, id: number): Observable<void> {
		return this.http.put<void>(
			`${this.apiUrl}/v1/menus/${id}`,
			menu,
			this.httpOptions
		);
	}

	public getAllAllergen(): Observable<Allergen[]> {
		return this.http.get<Allergen[]>(
			`${this.apiUrl}/v1/allergens`,
			this.httpOptions
		);
	}

	public createAllergen(allergen: CreateAllergen): Observable<{ id: number }> {
		return this.http.post<{ id: number }>(
			`${this.apiUrl}/v1/allergens`,
			allergen,
			this.httpOptions
		);
	}

	public updateAllergen(allergen: Allergen): Observable<void> {
		return this.http.put<void>(
			`${this.apiUrl}/v1/allergens`,
			allergen,
			this.httpOptions
		);
	}

	public deleteAllergen(id: number): Observable<void> {
		return this.http.delete<void>(
			`${this.apiUrl}/v1/allergens/${id}`,
			this.httpOptions
		);
	}

	public getAllIngredient(): Observable<Ingredient[]> {
		return this.http.get<Ingredient[]>(
			`${this.apiUrl}/v1/ingredients`,
			this.httpOptions
		);
	}

	public createIngredient(
		ingredient: CreateIngredient
	): Observable<{ id: number }> {
		return this.http.post<{ id: number }>(
			`${this.apiUrl}/v1/ingredients`,
			ingredient,
			this.httpOptions
		);
	}

	public updateIngredient(ingredient: Ingredient): Observable<void> {
		return this.http.put<void>(
			`${this.apiUrl}/v1/ingredients`,
			ingredient,
			this.httpOptions
		);
	}

	public deleteIngredient(id: number): Observable<void> {
		return this.http.delete<void>(
			`${this.apiUrl}/v1/ingredients/${id}`,
			this.httpOptions
		);
	}
}
