import {HttpErrorResponse} from "@angular/common/http";
import {inject} from "@angular/core";
import {CoreService} from "@casavina/utils/api-services/core.service";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap} from "rxjs";
import {CorePageActions} from "../core.actions";
import {IngredientApiActions, IngredientPageActions} from "./ingredient.actions";

export const loadIngredient$ = createEffect(
	(action$ = inject(Actions), coreService = inject(CoreService)) =>
		action$.pipe(
			ofType(IngredientPageActions.loadIngredient, CorePageActions.loadCore),
			switchMap(() =>
				coreService.getAllIngredient().pipe(
					map((ingredient) =>
						IngredientApiActions.loadIngredientSuccess({ingredient})
					),
					catchError((error: HttpErrorResponse) =>
						of(
							IngredientApiActions.loadIngredientFailure({
								error: error.message
							})
						)
					)
				)
			)
		),
	{functional: true}
);

export const createIngredient$ = createEffect(
	(action$ = inject(Actions), coreService = inject(CoreService)) =>
		action$.pipe(
			ofType(IngredientPageActions.createIngredient),
			switchMap((action) =>
				coreService.createIngredient(action.ingredient).pipe(
					map((res) =>
						IngredientApiActions.createIngredientSuccess({
							ingredient: {
								...action.ingredient,
								id: res.id
							}
						})
					),
					catchError((error: HttpErrorResponse) =>
						of(
							IngredientApiActions.createIngredientFailure({
								error: error.message
							})
						)
					)
				)
			)
		),
	{functional: true}
);

export const updateIngredient$ = createEffect(
	(action$ = inject(Actions), coreService = inject(CoreService)) =>
		action$.pipe(
			ofType(IngredientPageActions.updateIngredient),
			switchMap((action) =>
				coreService.updateIngredient(action.ingredient).pipe(
					map(() =>
						IngredientApiActions.updateIngredientSuccess({
							ingredient: action.ingredient
						})
					),
					catchError((error: HttpErrorResponse) =>
						of(
							IngredientApiActions.updateIngredientFailure({
								error: error.message
							})
						)
					)
				)
			)
		),
	{functional: true}
);

export const deleteIngredient$ = createEffect(
	(action$ = inject(Actions), coreService = inject(CoreService)) =>
		action$.pipe(
			ofType(IngredientPageActions.deleteIngredient),
			switchMap((action) =>
				coreService.deleteIngredient(action.id).pipe(
					map(() =>
						IngredientApiActions.deleteIngredientSuccess({id: action.id})
					),
					catchError((error: HttpErrorResponse) =>
						of(
							IngredientApiActions.deleteIngredientFailure({
								error: error.message
							})
						)
					)
				)
			)
		),
	{functional: true}
);
