import {HttpHeaders, HttpInterceptorFn} from "@angular/common/http";
import {inject} from "@angular/core";
import {CookieService} from "ngx-cookie-service";

export const authInterceptor: HttpInterceptorFn = (req, next) => {
	const cookie: CookieService = inject(CookieService);
	if (cookie.check("access-token")) {
		const accessToken: string = cookie.get("access-token");
		let headers: HttpHeaders;
		headers = req.headers.set("Authorization", "Bearer " + accessToken);
		headers = headers.set(
			"Cache-Control",
			"no-cache, no-store, max-age=0, must-revalidate"
		);
		const newRequest = req.clone({
			headers: headers,
			withCredentials: true
		});
		return next(newRequest);
	}
	return next(req);
};
