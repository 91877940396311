import {UsersApiActions, UsersPageActions} from "@casavina/data/store/Users/users.actions";
import {Users} from "@casavina/feature/models/users";
import {createFeature, createReducer, on} from "@ngrx/store";

export interface UsersState {
	users: Users[];
	error: string;
	status: "pending" | "loading" | "success" | "error";
}

const initialState: UsersState = {
	users: [],
	error: "",
	status: "pending"
};

export const usersFeature = createFeature({
	name: "users",
	reducer: createReducer(
		initialState,
		on(UsersPageActions.loadUsers, (state) => ({
			...state,
			status: "loading"
		})),
		on(UsersApiActions.loadUsersSuccess, (state, {users}) => ({
			...state,
			users,
			error: "",
			status: "success"
		})),
		on(UsersApiActions.loadUsersFailure, (state, {error}) => ({
			...state,
			error: error,
			status: "error"
		})),
		on(UsersPageActions.createUser, (state) => ({
			...state,
			status: "loading"
		})),
		on(UsersApiActions.createUserSuccess, (state, {user}) => ({
			...state,
			users: [...state.users, user],
			error: "",
			status: "success"
		})),
		on(UsersApiActions.createUserFailure, (state, {error}) => ({
			...state,
			error: error,
			status: "error"
		})),
		on(UsersPageActions.changePassword, (state) => ({
			...state,
			status: "loading"
		})),
		on(UsersApiActions.changePasswordSuccess, (state) => ({
			...state,
			error: "",
			status: "success"
		})),
		on(UsersApiActions.changePasswordFailure, (state, {error}) => ({
			...state,
			error: error,
			status: "error"
		})),
		on(UsersPageActions.deleteUser, (state) => ({
			...state,
			status: "loading"
		})),
		on(UsersApiActions.deleteUserSuccess, (state, {username}) => ({
			...state,
			users: state.users.filter((user) => user.username !== username),
			error: "",
			status: "success"
		})),
		on(UsersApiActions.deleteUserFailure, (state, {error}) => ({
			...state,
			error: error,
			status: "error"
		}))
	)
});
