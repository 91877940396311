import {HttpErrorResponse} from "@angular/common/http";
import {inject} from "@angular/core";
import {CoreService} from "@casavina/utils/api-services/core.service";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap} from "rxjs";
import {CorePageActions} from "../core.actions";
import {AllergenApiActions, AllergenPageActions} from "./allergen.actions";

export const loadAllergen$ = createEffect(
	(action$ = inject(Actions), coreService = inject(CoreService)) =>
		action$.pipe(
			ofType(AllergenPageActions.loadAllergen, CorePageActions.loadCore),
			switchMap(() =>
				coreService.getAllAllergen().pipe(
					map((allergen) =>
						AllergenApiActions.loadAllergenSuccess({allergen})
					),
					catchError((error: HttpErrorResponse) =>
						of(
							AllergenApiActions.loadAllergenFailure({
								error: error.message
							})
						)
					)
				)
			)
		),
	{functional: true}
);

export const createAllergen$ = createEffect(
	(action$ = inject(Actions), coreService = inject(CoreService)) =>
		action$.pipe(
			ofType(AllergenPageActions.createAllergen),
			switchMap((action) =>
				coreService.createAllergen(action.allergen).pipe(
					map((res) =>
						AllergenApiActions.createAllergenSuccess({
							allergen: {
								...action.allergen,
								id: res.id
							}
						})
					),
					catchError((error: HttpErrorResponse) =>
						of(
							AllergenApiActions.createAllergenFailure({
								error: error.message
							})
						)
					)
				)
			)
		),
	{functional: true}
);

export const updateAllergen$ = createEffect(
	(action$ = inject(Actions), coreService = inject(CoreService)) =>
		action$.pipe(
			ofType(AllergenPageActions.updateAllergen),
			switchMap((action) =>
				coreService.updateAllergen(action.allergen).pipe(
					map(() =>
						AllergenApiActions.updateAllergenSuccess({
							allergen: action.allergen
						})
					),
					catchError((error: HttpErrorResponse) =>
						of(
							AllergenApiActions.updateAllergenFailure({
								error: error.message
							})
						)
					)
				)
			)
		),
	{functional: true}
);

export const deleteAllergen$ = createEffect(
	(action$ = inject(Actions), coreService = inject(CoreService)) =>
		action$.pipe(
			ofType(AllergenPageActions.deleteAllergen),
			switchMap((action) =>
				coreService.deleteAllergen(action.id).pipe(
					map(() =>
						AllergenApiActions.deleteAllergenSuccess({id: action.id})
					),
					catchError((error: HttpErrorResponse) =>
						of(
							AllergenApiActions.deleteAllergenFailure({
								error: error.message
							})
						)
					)
				)
			)
		),
	{functional: true}
);
