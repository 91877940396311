import {Allergen} from "@casavina/feature/models/allergen";
import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {createFeature, createReducer, createSelector, on} from "@ngrx/store";
import {AllergenApiActions, AllergenPageActions} from "./allergen.actions";

interface AllergenState extends EntityState<Allergen> {
	error: string;
	status: "pending" | "loading" | "success" | "error";
}

const allergenAdapter: EntityAdapter<Allergen> = createEntityAdapter<Allergen>();

const initialState: AllergenState = allergenAdapter.getInitialState({
	error: "",
	status: "pending"
});

export const allergenFeature = createFeature({
	name: "allergen",
	reducer: createReducer(
		initialState,
		on(AllergenPageActions.loadAllergen, (state) => ({
			...state,
			status: "loading"
		})),
		on(AllergenApiActions.loadAllergenSuccess, (state, {allergen}) =>
			allergenAdapter.setAll(allergen, {
				...state,
				error: "",
				status: "success"
			})
		),
		on(AllergenApiActions.loadAllergenFailure, (state, {error}) => ({
			...state,
			error: error,
			status: "error"
		})),
		on(AllergenPageActions.createAllergen, (state) => ({
			...state,
			status: "loading"
		})),
		on(AllergenApiActions.createAllergenSuccess, (state, {allergen}) =>
			allergenAdapter.setOne(allergen, {
				...state,
				error: "",
				status: "success"
			})
		),
		on(AllergenApiActions.createAllergenFailure, (state, {error}) => ({
			...state,
			error: error,
			status: "error"
		})),
		on(AllergenPageActions.updateAllergen, (state) => ({
			...state,
			status: "loading"
		})),
		on(AllergenApiActions.updateAllergenSuccess, (state, {allergen}) =>
			allergenAdapter.upsertOne(allergen, {
				...state,
				error: "",
				status: "success"
			})
		),
		on(AllergenApiActions.updateAllergenFailure, (state, {error}) => ({
			...state,
			error: error,
			status: "error"
		})),
		on(AllergenPageActions.deleteAllergen, (state) => ({
			...state,
			status: "loading"
		})),
		on(AllergenApiActions.deleteAllergenSuccess, (state, {id}) =>
			allergenAdapter.removeOne(id, {
				...state,
				error: "",
				status: "success"
			})
		),
		on(AllergenApiActions.deleteAllergenFailure, (state, {error}) => ({
			...state,
			error: error,
			status: "error"
		}))
	),
	extraSelectors: ({selectEntities, selectIds}) => ({
		selectAll: createSelector(
			selectEntities,
			selectIds,
			(allergenEntities, allergenIds) =>
				allergenIds
					.map((id) => allergenEntities[id])
					.filter((allergen): allergen is Allergen => allergen !== undefined)
		),
		selectAllOrderByShortName: createSelector(
			selectEntities,
			selectIds,
			(allergenEntities, allergenIds) =>
				allergenIds
					.map((id) => allergenEntities[id])
					.sort((firstAllergen, secondAllergen) => {
						if (firstAllergen && secondAllergen) {
							const firstShortName = firstAllergen.shortName;
							const secondShortName = secondAllergen.shortName;
							const isFirstShortNameNumber = /^\d+$/.test(firstShortName);
							const isSecondShortNameNumber = /^\d+$/.test(secondShortName);
							if (isFirstShortNameNumber && isSecondShortNameNumber) {
								return (
									parseInt(firstShortName, 10) - parseInt(secondShortName, 10)
								);
							}
							return firstShortName.localeCompare(secondShortName);
						}
						return 0;
					})
					.filter((allergen): allergen is Allergen => allergen !== undefined)
		),
		selectAllergenById: (id: number) =>
			createSelector(
				selectEntities,
				(allergenEntities) => allergenEntities[id]
			)
	})
});
