import {inject} from "@angular/core";
import {UsersApiActions, UsersPageActions} from "@casavina/data/store/Users/users.actions";
import {UserService} from "@casavina/utils/api-services/user.service";
import {ToastService} from "@casavina/utils/services/toast.service";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";

export const loadUsers$ = createEffect(
	(action$ = inject(Actions), userService = inject(UserService)) =>
		action$.pipe(
			ofType(UsersPageActions.loadUsers),
			switchMap(() =>
				userService.getAllUsers().pipe(
					map((users) => UsersApiActions.loadUsersSuccess({users})),
					catchError(() =>
						of(
							UsersApiActions.loadUsersFailure({
								error: "Benutzer kann nicht geladen werden"
							})
						)
					)
				)
			)
		),
	{functional: true}
);

export const createUser = createEffect(
	(action$ = inject(Actions), userService = inject(UserService)) =>
		action$.pipe(
			ofType(UsersPageActions.createUser),
			switchMap((action) =>
				userService.createUser(action.user).pipe(
					map(() =>
						UsersApiActions.createUserSuccess({
							user: {
								username: action.user.username,
								enabled: action.user.enabled
							}
						})
					),
					catchError(() =>
						of(
							UsersApiActions.createUserFailure({
								error: "Benutzer kann nicht angelegt werden"
							})
						)
					)
				)
			)
		),
	{functional: true}
);

export const createUserSuccess$ = createEffect(
	(action$ = inject(Actions), messageService = inject(ToastService)) =>
		action$.pipe(
			ofType(UsersApiActions.createUserSuccess),
			tap(() => {
				messageService.addNewMessage(
					"success",
					"Erfolgreich hinzugefügt",
					"Der Benutzer wurde erfolgreich hinzugefügt"
				);
			})
		),
	{functional: true, dispatch: false}
);

export const changePassword$ = createEffect(
	(action$ = inject(Actions), userService = inject(UserService)) =>
		action$.pipe(
			ofType(UsersPageActions.changePassword),
			switchMap((action) =>
				userService.changePassword(action.changePassword).pipe(
					map(() => UsersApiActions.changePasswordSuccess()),
					catchError(() =>
						of(
							UsersApiActions.changePasswordFailure({
								error: "Passwort kann nicht geändert werden"
							})
						)
					)
				)
			)
		),
	{functional: true}
);

export const changePasswordSuccess$ = createEffect(
	(action$ = inject(Actions), messageService = inject(ToastService)) =>
		action$.pipe(
			ofType(UsersApiActions.changePasswordSuccess),
			tap(() => {
				messageService.addNewMessage(
					"success",
					"Erfolgreich aktualisiert",
					"Passwort wurde erfolgreich aktualisiert"
				);
			})
		),
	{functional: true, dispatch: false}
);

export const deleteUser$ = createEffect(
	(action$ = inject(Actions), userService = inject(UserService)) =>
		action$.pipe(
			ofType(UsersPageActions.deleteUser),
			switchMap((action) =>
				userService.deleteUser(action.username).pipe(
					map(() =>
						UsersApiActions.deleteUserSuccess({username: action.username})
					),
					catchError(() =>
						of(
							UsersApiActions.deleteUserFailure({
								error: "Benutzer kann nicht gelöscht werden"
							})
						)
					)
				)
			)
		),
	{functional: true}
);

export const deleteUserSuccess$ = createEffect(
	(action$ = inject(Actions), messageService = inject(ToastService)) =>
		action$.pipe(
			ofType(UsersApiActions.deleteUserSuccess),
			tap(() => {
				messageService.addNewMessage(
					"success",
					"Erfolgreich gelöscht",
					"Der Benutzer wurde erfolgreich gelöscht"
				);
			})
		),
	{functional: true, dispatch: false}
);
