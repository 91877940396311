import {ChangePassword, CreateUser, Users} from "@casavina/feature/models/users";
import {createActionGroup, emptyProps, props} from "@ngrx/store";

export const UsersPageActions = createActionGroup({
	source: "Users Page",
	events: {
		"Load Users": emptyProps(),
		"Create User": props<{ user: CreateUser }>(),
		"Change Password": props<{ changePassword: ChangePassword }>(),
		"Delete User": props<{ username: string }>()
	}
});

export const UsersApiActions = createActionGroup({
	source: "Users API",
	events: {
		"Load Users Success": props<{ users: Users[] }>(),
		"Load Users Failure": props<{ error: string }>(),
		"Create User Success": props<{ user: Users }>(),
		"Create User Failure": props<{ error: string }>(),
		"Change Password Success": emptyProps(),
		"Change Password Failure": props<{ error: string }>(),
		"Delete User Success": props<{ username: string }>(),
		"Delete User Failure": props<{ error: string }>()
	}
});
