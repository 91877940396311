import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {CreateOffer, Offer} from "@casavina/feature/models/offer";
import {environment} from "@casavina/utils/environments/environment";
import {Observable} from "rxjs";

@Injectable({
	providedIn: "root"
})
export class OfferService {
	private readonly apiUrl: string = environment.apiUrl;
	private httpOptions = {
		headers: new HttpHeaders({
			"Content-Type": "application/json"
		}),
		withCredentials: true
	};

	constructor(private http: HttpClient) {
	}

	public getAllOffer(): Observable<Offer[]> {
		return this.http.get<Offer[]>(`${this.apiUrl}/v1/offers`, this.httpOptions);
	}

	public createOffer(offer: CreateOffer): Observable<Offer> {
		return this.http.post<Offer>(
			`${this.apiUrl}/v1/offers`,
			offer,
			this.httpOptions
		);
	}

	public updateOffer(offer: Offer): Observable<void> {
		return this.http.put<void>(
			`${this.apiUrl}/v1/offers`,
			offer,
			this.httpOptions
		);
	}

	public deleteOffer(id: string): Observable<void> {
		return this.http.delete<void>(
			`${this.apiUrl}/v1/offers/${id}`,
			this.httpOptions
		);
	}
}
