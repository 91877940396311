import {CreateIngredient, Ingredient} from "@casavina/feature/models/ingredient";
import {createActionGroup, emptyProps, props} from "@ngrx/store";

export const IngredientPageActions = createActionGroup({
	source: "Core - Ingredient Page",
	events: {
		"Load Ingredient": emptyProps(),
		"Create Ingredient": props<{ ingredient: CreateIngredient }>(),
		"Update Ingredient": props<{ ingredient: Ingredient }>(),
		"Delete Ingredient": props<{ id: number }>()
	}
});

export const IngredientApiActions = createActionGroup({
	source: "Core - Ingredient API",
	events: {
		"Load Ingredient Success": props<{ ingredient: Ingredient[] }>(),
		"Load Ingredient Failure": props<{ error: string }>(),
		"Create Ingredient Success": props<{ ingredient: Ingredient }>(),
		"Create Ingredient Failure": props<{ error: string }>(),
		"Update Ingredient Success": props<{ ingredient: Ingredient }>(),
		"Update Ingredient Failure": props<{ error: string }>(),
		"Delete Ingredient Success": props<{ id: number }>(),
		"Delete Ingredient Failure": props<{ error: string }>()
	}
});
