<button #menuMobileTrigger = "matMenuTrigger" [matMenuTriggerFor] = "menuMobile" mat-icon-button >
	<mat-icon fontSet = "material-symbols-outlined" >
		{{ menuMobileTrigger.menuOpen ? 'close' : 'menu' }}
	</mat-icon >
</button >

<mat-menu #menuMobile = "matMenu" >
	<ng-template matMenuContent >
		<div style = "padding: 0.75rem 1.5rem" >
			<ng-container *ngFor = "let item of navbarItems" >
				<button *ngIf = "item.visible !== undefined ? item.visible : true"
				        [routerLink] = "item.routerLink"
				        mat-menu-item >
					<div [routerLinkActiveOptions] = "{ exact: true }"
					     class = "navbar-items-details"
					     routerLinkActive = "active" >
						{{ item.label }}
					</div >
				</button >
				<ng-container *ngIf = "item.items && item.items.length > 0" >
					<div class = "nav-child-list" >
						<mat-nav-list >
							<ng-container *ngFor = "let childItem of item.items" >
								<a *ngIf = "
                    childItem.visible !== undefined ? childItem.visible : true
                  " [routerLink] = "childItem.routerLink" mat-list-item >
									<div [routerLinkActiveOptions] = "{ exact: true }"
									     class = "navbar-items-details"
									     routerLinkActive = "active" >
										{{ childItem.label }}
									</div >
								</a >
							</ng-container >
						</mat-nav-list >
					</div >
				</ng-container >
			</ng-container >
		</div >
	</ng-template >
</mat-menu >
