import {CreateReservation, Reservation} from "@casavina/feature/models/reservation";
import {createActionGroup, emptyProps, props} from "@ngrx/store";

export const ReservationPageActions = createActionGroup({
	source: "Reservation Page",
	events: {
		"Load Reservation": emptyProps(),
		"Create Reservation": props<{ reservation: CreateReservation }>(),
		"Toggle Reservation Status": props<{ reservation: Reservation }>()
	}
});

export const ReservationApiActions = createActionGroup({
	source: "Reservation API",
	events: {
		"Load Reservation Success": props<{ reservations: Reservation[] }>(),
		"Load Reservation Failure": props<{ error: string }>(),
		"Create Reservation Success": props<{ id: string }>(),
		"Create Reservation Failure": props<{ error: string }>(),
		"Toggle Reservation Status Success": props<{ reservation: Reservation }>(),
		"Toggle Reservation Status Failure": props<{ error: string }>()
	}
});
