import {HttpErrorResponse} from "@angular/common/http";
import {inject} from "@angular/core";
import {CoreService} from "@casavina/utils/api-services/core.service";
import {FacadeService} from "@casavina/utils/facade-store/facade.service";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, EMPTY, map, of, switchMap, zip} from "rxjs";
import {CategoryPageActions} from "../Category";
import {CorePageActions} from "../core.actions";
import {MenuApiActions, MenuPageActions} from "./menu.actions";

export const loadMenu$ = createEffect(
	(action$ = inject(Actions), coreService = inject(CoreService)) =>
		action$.pipe(
			ofType(MenuPageActions.loadMenu, CorePageActions.loadCore),
			switchMap(() =>
				coreService.getAllMenus().pipe(
					map((menu) => MenuApiActions.loadMenuSuccess({menu})),
					catchError((error: HttpErrorResponse) =>
						of(
							MenuApiActions.loadMenuFailure({
								error: error.message
							})
						)
					)
				)
			)
		),
	{functional: true}
);

export const createMenu$ = createEffect(
	(action$ = inject(Actions), coreService = inject(CoreService)) =>
		action$.pipe(
			ofType(MenuPageActions.createMenu),
			switchMap((action) =>
				coreService.createMenuItem(action.menu).pipe(
					map((res) =>
						MenuApiActions.createMenuSuccess({
							menu: {
								...action.menu,
								id: res.id
							}
						})
					),
					catchError((error: HttpErrorResponse) =>
						of(
							MenuApiActions.createMenuFailure({
								error: error.message
							})
						)
					)
				)
			)
		),
	{functional: true}
);

export const createMenuSuccess$ = createEffect(
	(action$ = inject(Actions), facadeService = inject(FacadeService)) =>
		action$.pipe(
			ofType(MenuApiActions.createMenuSuccess),
			switchMap((action) =>
				zip(of(action), facadeService.getCategoryEntities())
			),
			switchMap(([action, entities]) => {
				const category = entities[action.menu.category];
				if (category !== undefined) {
					const newCategory = {
						...category,
						menus: [...category.menus, ...[action.menu.id]]
					};
					return of(newCategory);
				}
				return EMPTY;
			}),
			map((category) => CategoryPageActions.localUpdateCategory({category}))
		),
	{functional: true}
);

export const updateMenu$ = createEffect(
	(action$ = inject(Actions), coreService = inject(CoreService)) =>
		action$.pipe(
			ofType(MenuPageActions.updateMenu),
			switchMap((action) =>
				coreService.updateMenuItem(action.menu, action.id).pipe(
					map(() =>
						MenuApiActions.updateMenuSuccess({
							menu: {
								...action.menu,
								id: action.id
							}
						})
					),
					catchError((error: HttpErrorResponse) =>
						of(
							MenuApiActions.updateMenuFailure({
								error: error.message
							})
						)
					)
				)
			)
		),
	{functional: true}
);

export const deleteMenu$ = createEffect(
	(action$ = inject(Actions), coreService = inject(CoreService)) =>
		action$.pipe(
			ofType(MenuPageActions.deleteMenu),
			switchMap((action) =>
				coreService.deleteMenuById(action.id).pipe(
					map(() => MenuApiActions.deleteMenuSuccess({id: action.id})),
					catchError((error: HttpErrorResponse) =>
						of(
							MenuApiActions.deleteMenuFailure({
								error: error.message
							})
						)
					)
				)
			)
		),
	{functional: true}
);
