import {AsyncPipe, NgIf} from "@angular/common";
import {ApplicationRef, ChangeDetectionStrategy, Component, DestroyRef, OnInit} from "@angular/core";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {RouterOutlet} from "@angular/router";
import {SwUpdate, VersionReadyEvent} from "@angular/service-worker";
import {NavbarItem} from "@casavina/ui/navbar/models/navbar-item";
import {NavbarComponent} from "@casavina/ui/navbar/navbar.component";
import {NavbarEndDirective} from "@casavina/ui/navbar/utils/navbar-end.directive";
import {FacadeService} from "@casavina/utils/facade-store/facade.service";
import deLocale from "@casavina/utils/i18n/primeng-locale.de";
import {CookieService} from "ngx-cookie-service";
import {PrimeNGConfig} from "primeng/api";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {ToastModule} from "primeng/toast";
import {concat, distinctUntilChanged, filter, interval, switchMap} from "rxjs";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
	standalone: true,
	imports: [
		NgIf,
		MatButtonModule,
		RouterOutlet,
		AsyncPipe,
		MatIconModule,
		ToastModule,
		ConfirmDialogModule,
		NavbarComponent,
		NavbarEndDirective
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
	public title = "Casa  Vina - Internal";
	public navbarItems: NavbarItem[] = [];

	constructor(
		private appRef: ApplicationRef,
		private updates: SwUpdate,
		protected facadeService: FacadeService,
		private destroyRef: DestroyRef,
		private primeConfig: PrimeNGConfig,
		private cookieService: CookieService
	) {
	}

	ngOnInit() {
		this.primeConfig.setTranslation(deLocale);
		const accessToken = this.cookieService.get("access-token");
		if (!!accessToken) {
			this.facadeService.setAdmin(
				JSON.parse(atob(accessToken.split(".")[1])).scope.includes("ROLE_ADMIN")
			);
		}
		this.navbarItems = [
			{
				label: "Reservierungen",
				routerLink: "/"
			},
			{
				label: "Menü",
				items: [
					{
						label: "Allergien",
						routerLink: "/menu/allergen"
					},
					{
						label: "Menü",
						routerLink: "/menu"
					},
					{
						label: "Zutaten",
						routerLink: "/menu/ingredient"
					}
				]
			},
			{
				label: "Benutzer",
				routerLink: "/user",
				visible: this.facadeService.isAdmin()
			},
			{
				label: "Veranstaltungen",
				routerLink: "/event"
			},
			{
				label: "Wochenangebot",
				routerLink: "/event/offer"
			}
		];

		const appIsStable$ = this.appRef.isStable.pipe(
			distinctUntilChanged(),
			filter((isStable) => isStable)
		);
		const interval$ = interval(6 * 3600 * 1000);
		concat(appIsStable$, interval$)
			.pipe(
				switchMap(() => this.updates.versionUpdates),
				filter((evt): evt is VersionReadyEvent => evt.type === "VERSION_READY"),
				takeUntilDestroyed(this.destroyRef)
			)
			.subscribe(async (evt) => {
				if (
					confirm(
						`New version ${evt.latestVersion.hash} available. Load new version?`
					)
				) {
					await this.updates.activateUpdate();
					window.location.reload();
				}
			});
	}
}
