import {Category, CreateCategory} from "@casavina/feature/models/category";
import {createActionGroup, emptyProps, props} from "@ngrx/store";

export const CategoryPageActions = createActionGroup({
	source: "Core - Category Page",
	events: {
		"Load Category": emptyProps(),
		"Create Category": props<{ category: CreateCategory }>(),
		"Update Category": props<{ category: Category }>(),
		"Delete Category": props<{ id: number }>(),
		"Local Update Category": props<{ category: Category }>()
	}
});

export const CategoryApiActions = createActionGroup({
	source: "Core - Category API",
	events: {
		"Load Category Success": props<{ categories: Category[] }>(),
		"Load Category Failure": props<{ error: string }>(),
		"Create Category Success": props<{ category: Category }>(),
		"Create Category Failure": props<{ error: string }>(),
		"Update Category Success": props<{ category: Category }>(),
		"Update Category Failure": props<{ error: string }>(),
		"Delete Category Success": props<{ id: number }>(),
		"Delete Category Failure": props<{ error: string }>()
	}
});
